<template>
  <Phrases />
</template>

<script>
import Phrases from "@/components/Tables/Phrases.vue";

export default {
  components: { Phrases },
};
</script>
